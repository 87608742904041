import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import IndividualForm from './IndividualForm'
import BusinessForm from './BusinessForm'
import Grid from '../../UIComponents/Grid/Grid'
import AddSeller from '../../UIComponents/AddSeller/AddSeller'
import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import { resetCreateSeller } from '../../Actions/seller'
import { resetBankAccounts } from '../../Actions/bankAccounts'
import { getCategoryCodes } from '../../Actions/merchantCategoryCodes'
import { getBanks } from '../../Actions/banks'

const mapDispatchToProps = dispatch => bindActionCreators({
  resetCreateSeller,
  getCategoryCodes,
  resetBankAccounts,
  getBanks
}, dispatch)

const mapStateToProps = (state) => {
  const { createSellerData } = state.seller
  const { createBankAccountRequesting, createBankAccountError, updateReceivingPolicyRequest, updateReceivingPolicyError } = state.recipientMeans

  return {
    createSellerData,
    createBankAccountRequesting,
    createBankAccountError,
    updateReceivingPolicyRequest,
    updateReceivingPolicyError
  }
}

class AddSellerComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      step: 1
    }
  }

  componentDidMount = () => {
    this.props.getCategoryCodes()
    this.props.getBanks()
  }

  handleStep= step => this.setState({ step })

  returnHome = () => this.handleStep(1)

  handleClose = () => {
    this.props.resetCreateSeller()
    this.props.resetBankAccounts()
    this.props.handleClose()
  }

  render () {
    const { step } = this.state

    return (
      <Grid noPadding>
        {step === 1 && (
          <Fragment>
            <HeaderSidebar
              title={<><strong>Adicionar</strong> Estabelecimento</>}
              handleClose={this.handleClose}
            />
            <AddSeller
              individualAction={() => this.handleStep(2)}
              businessAction={() => this.handleStep(3)}
            />
          </Fragment>
        )}
        {step === 2 && (
          <IndividualForm goBack={this.returnHome} handleClose={this.handleClose} />
        )}
        {step === 3 && (
          <BusinessForm goBack={this.returnHome} handleClose={this.handleClose} />
        )}
      </Grid>
    )
  }
}

AddSellerComponent.propTypes = {
  getCategoryCodes: PropTypes.func,
  getBanks: PropTypes.func,
  resetCreateSeller: PropTypes.func,
  resetBankAccounts: PropTypes.func,
  handleClose: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSellerComponent)
