import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import Button from 'src/UIComponents/Button/ButtonDefault'
import AddSeller from './AddSeller'
import { getApiSettings } from '../../Actions/settings'
import { useDispatch } from 'react-redux'

const AddSellerComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch({
    path: '*/adicionar-estabelecimento'
  })

  const [showSidebar, setShowSidebar] = useState(!!match)

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        setShowSidebar(true)
      }, 500)
    }
  }, [])

  const handleClose = () => {
    setShowSidebar(false)

    setTimeout(() => {
      history.push(match.params[0])
    }, 500)
  }

  const handleClick = () => {
    dispatch(getApiSettings(true))
    history.push(`${location.pathname}/adicionar-estabelecimento`)

    setTimeout(() => {
      setShowSidebar(true)
    }, 500)
  }

  return (
    <>
      <Button widthAuto onClick={handleClick} data-tracking={`Adicionar Estabelecimento`} data-test='add-seller-btn'>
          Adicionar estabelecimento
      </Button>
      {
        match && <Sidebar show={showSidebar}>
          <AddSeller handleClose={handleClose} />
        </Sidebar>
      }
    </>
  )
}
export default AddSellerComponent
