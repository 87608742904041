import React, { useState } from 'react'
import HeaderSidebar from '../../../../UIComponents/HeaderSidebar/HeaderSidebar'
import Button from '../../../../UIComponents/Button/ButtonDefault'
import { useHistory } from 'react-router-dom'

import { toMoney, parseDate } from '../../../../Utils/Utils'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import DateLoader from 'src/Components/SaleDetail/DateLoader'
import ResumeLoader from 'src/Components/SaleDetail/ResumeLoader'
import Grid from 'src/UIComponents/Grid/Grid'
import { Checkbox } from '@material-ui/core'

import styled from 'styled-components'
import Label from '../../../../UIComponents/Label/Label'
import { darkGray, green, darkerGray } from 'src/Styles/settings/Constants'
import { smallerText } from '../../../../Styles/settings/Constants'
import * as PropTypes from 'prop-types'
import moment from 'moment'

export const SummarySimulationAnticipation = ({ anticipation, setShowSidebar, setAnticipation, setShowAnticipationPopup }) => {
  const history = useHistory()

  const [checkbox, setCheckbox] = useState(false)

  const headerSubtitle = () => {
    if (!anticipation) {
      return <DateLoader />
    } else if (anticipation) {
      return parseDate(moment(anticipation?.created_at).subtract(3, 'hours'), 'DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')
    }
  }

  const handleClose = () => {
    setShowSidebar(false)
    setAnticipation(null)

    setTimeout(() => {
      history.push('/antecipacoes')
    }, 500)
  }

  return (
    <>
      <HeaderSidebar
        handleClose={() => handleClose()}
        title={<><strong>Resumo</strong> da simulação</>}
        subTitle={headerSubtitle()}
      />
      <SidebarContainer>
        {!anticipation && <ResumeLoader />}
        {anticipation &&
          <FooterContainer>
            <Grid noPadding marginTop={'30px'}>
              <StatusBar color={green} />
              <Grid.Row auto>
                {anticipation?.original_request?.target_amount && <Grid.Col cols={2}>
                  <Label color={darkGray} fontSize={smallerText}>Valor líquido solicitado</Label>
                  <Label width='100%' textAlign='left' bold color={green} overflow>{toMoney(anticipation?.original_request?.target_amount / 100)}</Label>
                </Grid.Col>}
                <Grid.Col cols={12}>
                  <Label color={darkGray} textAlign='left' fontSize={smallerText} >Markup</Label>
                  <Label overflow width='100%' textAlign='left' bold color={green}>{(anticipation?.partner_fee * 100).toPercentage()}</Label>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row >
                <Grid.Col cols={14} >
                  <Label color={darkGray} fontSize={smallerText}>Intervalo de datas das transações</Label>
                  <Label overflow color={darkGray} breakLine textAlign='left' >
                  de <Label bold color={green} >  {parseDate(anticipation?.original_request?.min_transaction_date, 'DD/MM/YYYY')}   <img alt='calendarIcon' src={`../../../../assets/icons/others/calendar.svg`} /></Label>                 até<Label bold color={green} >    {parseDate(anticipation?.original_request?.max_transaction_date, 'DD/MM/YYYY')}   <img alt='calendarIcon' src={`../../../../assets/icons/others/calendar.svg`} />  </Label>
                  </Label>
                </Grid.Col>
              </Grid.Row>
              <br />
              <Grid.Row>
                <Grid.Col cols={10}>
                  <Label color={darkGray} fontSize={smallerText}>Valor total bruto a ser antecipado</Label>
                </Grid.Col>
                <Grid.Col cols={6}>
                  <Label width='100%' textAlign='right' bold color={green}>{toMoney(anticipation?.prepayment_gross_amount / 100)}</Label>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col cols={10}>
                  <Label color={darkGray} fontSize={smallerText}> Valor das taxas </Label>
                </Grid.Col>
                <Grid.Col cols={6}>
                  <Label width='100%' textAlign='right' bold color={green}> - {toMoney((anticipation?.prepayment_gross_amount - anticipation.prepayment_net_amount) / 100)}</Label>
                </Grid.Col>
              </Grid.Row>
              <hr />
              <Grid.Row>
                <Grid.Col cols={10}>
                  <Label color={darkGray} fontSize={smallerText}> Valor total líquido a ser recebido (somatório dos recebíveis)</Label>
                </Grid.Col>
                <Grid.Col cols={6}>
                  <Label width='100%' textAlign='right' bold color={green}>{toMoney(anticipation?.prepayment_net_amount / 100)}</Label>
                </Grid.Col>
              </Grid.Row>
              <br />
              <Grid.Row auto>
                <Reminder>
                  <p> Lembre-se de combinar com o Estabelecimento Comercial o acréscimo das taxas.</p>
                </Reminder>
              </Grid.Row>
              <br />
              <Grid.Row auto>
                <Checkbox onClick={() => setCheckbox(!checkbox)} />
                <Label textAlign='left' color={darkerGray} >
                  Declaro estar ciente e responsável por informar devidamente o Estabelecimento Comercial acerca dos valores e taxas acrescidos à solicitação.
                </Label>
              </Grid.Row>
              <br />
              <Grid.Row auto>
                <Button ghost outline onClick={() => history.push('/antecipacoes')}>Cancelar</Button>
                <Button disabled={!checkbox} onClick={() => setShowAnticipationPopup(true)}>Realizar antecipação</Button>
              </Grid.Row>
            </Grid>
            <br />
            <AttentionDiv>
              <p>Atenção: A Zoop não se responsabiliza por valores preenchidos incorretamente ou pela negociação entre o Marketplace e Estabelecimento Comercial.</p>
            </AttentionDiv>
          </FooterContainer>
        }
      </SidebarContainer>
    </>
  )
}

const StatusBar = styled.div`
  width: 6px;
  height: 96px;
  background-color: ${(props) => props.color};
  position: absolute;
  left: 0px;
`

const AttentionDiv = styled.div`
  background: #F1F1F1;
  color: #737373;
  padding: 3rem 3rem 4rem 3rem;
  text-align: center;
  font-weight: 400;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-bottom: -35px;
`

const Reminder = styled.div`
  width: 405px;
  padding: 2px 6px 2px 6px;
  border-radius: 5px;
  color:#737373;
  background: linear-gradient(0deg, rgba(255, 160, 17, 0.4), rgba(255, 160, 17, 0.4));
  border: 1px solid #FFA011;
  
  p{
    width: 368px;
    font-size: 10px;
    text-align:center;
    margin: auto;
  }
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 95%;
`

SummarySimulationAnticipation.propTypes = {
  anticipation: PropTypes.object,
  setShowSidebar: PropTypes.func,
  setAnticipation: PropTypes.func,
  setShowAnticipationPopup: PropTypes.func
}
