import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import t from 'tcomb-form'
import { createAnticipationSimulation } from '../../../Actions/anticipations'
import { bigPadding, largePadding } from '../../../Styles/settings/Constants'
import ButtonDefault from '../../../UIComponents/Button/ButtonDefault'
import DisplaySeller from '../../../UIComponents/DisplaySeller/DisplaySeller'
import FormZ from '../../../UIComponents/FormZ/FormZ'
import Grid from '../../../UIComponents/Grid/Grid'
import { AnticipationPopupProcessing } from '../../../UIComponents/Popups/AnticipationPopupProcessing'
import { getFormOptionsWithValidation } from '../../../Utils/FormUtils'
import createToast from '../../../Utils/createToast'
import formValidators from '../../../Utils/formValidators'
import DecimalFactory from '../../FormFactories/DecimalFactory'
import MoneyFactory from '../../FormFactories/MoneyFactory'
import { SelectSeller } from '../../SelectSeller/SelectSeller'
import { Template } from './template'

const Form = () => {
  const history = useHistory()

  const [formValues, setFormValues] = useState({
    seller: null,
    markup: null,
    prepaymentDate: null,
    startDate: null,
    endDate: null
  })

  const [showFormValidation, setShowFormValidation] = useState(false)
  const [showAnticipationPopup, setShowAnticipationPopup] = useState(false)
  const [loadingAnticipation, setLoadingAnticipation] = useState(false)

  const cleanFields = () => {
    setFormValues({
      seller: null,
      markup: null,
      targetAmount: null,
      prepaymentDate: null
    })

    setShowFormValidation(false)
  }

  const options = {
    template: (locals) => Template(locals),
    fields: {
      markup: {
        factory: DecimalFactory,
        autocomplete: false,
        config: {
          id: 'markup',
          minValue: 0,
          maxValue: 100,
          placeholder: '00,00%',
          errorMessage: 'Digite um valor válido de 00,01% a 100,00%',
          maxLength: 6
        }
      },
      targetAmount: {
        factory: MoneyFactory,
        autocomplete: false,
        config: {
          id: 'targetAmount',
          minValue: 0,
          placeholder: 'R$ 00,00'
        }
      }
    }
  }

  const struct = t.struct({
    markup: t.maybe(formValidators.MaxValue(100)),
    targetAmount: t.maybe(formValidators.MinValue(0))
  })

  const onChange = (values) => {
    setFormValues({
      ...formValues,
      ...values
    })
  }

  const isValid = t.validate(formValues, struct).isValid() && formValues.seller

  const onSubmit = () => {
    setShowFormValidation(!isValid)

    if (isValid) {
      setShowAnticipationPopup(true)
    }
  }

  const handleSimulation = async () => {
    setShowAnticipationPopup(true)
    setLoadingAnticipation(true)

    const dataFromApi = await createAnticipationSimulation(
      formValues.seller.id,
      formValues.targetAmount,
      formValues.markup / 100
    )

    setLoadingAnticipation(false)

    if (dataFromApi.status === 400) {
      createToast('error', 'Ops.. Antecipação não pode ser <strong>solicitada. Tente novamente.</strong>')
    }

    if (dataFromApi.status === 201) {
      setShowAnticipationPopup(false)
      history.push('/antecipacoes')
    }
  }

  return (
    <>
      <Grid>
        <Grid.Row >
          <Grid.Col cols={6}>
            {!formValues.seller && <SelectSeller
              placeholder={'Escolher estabelecimento'}
              action={(value) => onChange({ seller: value })}
              hasError={showFormValidation && !formValues.seller}
              errorMessage='Selecione um estabelecimento'
              className='select-seller-anticipation'
            />}

            {formValues.seller && (
              <DisplaySeller seller={formValues.seller} onClick={() => onChange({ seller: null })} />
            )}
          </Grid.Col>
          <Grid.Col cols={8}>
            <FormZ
              options={showFormValidation
                ? getFormOptionsWithValidation(options, formValues, struct)
                : options}
              struct={struct}
              values={formValues}
              onChange={(values) => onChange(values)}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <InformationContainer>
        <ButtonContainer>
          <ButtonDefault onClick={cleanFields} ghost outline widthAuto>{'Limpar preenchimento' }</ButtonDefault>
          <ButtonDefault onClick={onSubmit} disabled={!isValid}>Simular antecipação</ButtonDefault>
        </ButtonContainer>
      </InformationContainer>

      {showAnticipationPopup &&
        <AnticipationPopupProcessing
          handleClose={() => setShowAnticipationPopup(false)}
          handleSubmit={handleSimulation}
          isLoading={loadingAnticipation}
        />}
    </>
  )
}

const InformationContainer = styled.div`
  display: flex;
  align-items:center ;
  padding: ${largePadding} ${bigPadding};
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap:20px;
  padding: 40px;
 
  button{
  width: auto;
  padding: 12px 24px  12px  24px;
  }
`

export { Form }
