import styled from 'styled-components'
import { bigPadding, bigText, biggerText, darkGray, largePadding, lighterGray, textBold, white } from '../../../Styles/settings/Constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export const AnticipationContainer = styled.div`
  padding: ${largePadding} ${bigPadding};
  background-color: ${lighterGray};
  height: 422px;
 
  .select-seller-anticipation {
    margin-top: 0;
    height: 5.7rem;    

    .search-input {
      input {
        height: 58px;
      }
    }
    
    div + div {
      padding-top: 5.7rem;
      
    }
  }

  form {
    input {
      -webkit-text-fill-color: ${({ theme: { color } }) => color} !important;
      font-size: ${biggerText} !important;
      font-weight: ${textBold};

      ::placeholder {
        font-size: ${biggerText} !important;
        font-weight: normal;
        -webkit-text-fill-color: ${darkGray} !important;
      }
    }
  }
`

export const DateSepator = styled.div`
  padding-block: 15px;
  font-size: ${bigText};
  margin: 0px 20px 10px 10px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InformationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelWithTooltip = styled.div`
  display: flex;
  gap: 3px;
`

export const InfoIcon = styled.div`
  padding: 0px 4px;
  background-color: ${darkGray};
  color: ${white};
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
`
