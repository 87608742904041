import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import t from 'tcomb-form'
import moment from 'moment'
import { compact, isEmpty, mapValues } from 'lodash'
import { Collapse } from 'reactstrap'

import { generatePassword } from '../../Utils/Utilities'
import { getFormOptionsWithValidation } from '../../Utils/FormUtils'
import formValidators from '../../Utils/formValidators'
import { getAddressFromPostalCode } from 'src/Utils/Utils'
import { StateTypes } from '../../Constants/StateTypes'
import { AccountTypes } from '../../Constants/AccountTypes'
import {
  createSeller,
  resetCreateSeller,
  finalCreateSeller
} from '../../Actions/seller'
import {
  createBankAccountToken,
  updateReceivingPolicy,
  resetBankAccounts
} from '../../Actions/bankAccounts'
import { verifyDataSeller } from '../../Actions/sellers'

import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import DocumentFactory from '../FormFactories/DocumentFactory'
import DatePickerFactory from '../FormFactories/DatePickerFactory'
import PhoneFactory from '../FormFactories/PhoneFactory'
import SelectMerchantCodeFactory from '../FormFactories/SelectMerchantCodeFactory'
import SelectBankCodeFactory from '../FormFactories/SelectBankCodeFactory'
import CheckboxFactory from '../FormFactories/CheckboxFactory'
import PostalCodeFactory from '../FormFactories/PostalCodeFactory'
import NumberFactory from '../FormFactories/NumberFactory'
import SelectFactory from '../FormFactories/SelectFactory'
import MoneyFactory from '../FormFactories/MoneyFactory'

import Grid from '../../UIComponents/Grid/Grid'
import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import FormZ from '../../UIComponents/FormZ/FormZ'
import AddSellerTemplate from '../../UIComponents/TemplatesForm/AddSellerTemplate'
import AddAddressTemplate from '../../UIComponents/TemplatesForm/AddAddressTemplate'
import AddBankingTemplate from '../../UIComponents/TemplatesForm/AddBankingTemplate'
import SubTitle from '../../UIComponents/Title/SubTitle'
import CreateBankingAccount from '../../UIComponents/CreateBankingAccount/CreateBankingAccount'
import Button from '../../UIComponents/Button/ButtonDefault'
import SuccessCard from '../../UIComponents/SuccessCard/SuccessCard'
import { AddSellerMessageSuccess } from '../../UIComponents/SuccessMessages/SuccessMessages'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import AlertCloseSidebar from 'src/UIComponents/AlertCloseSidebar/AlertCloseSidebar'
import { Step } from 'src/UIComponents/Stepper'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const LENGTH_CNPJ = 14

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSeller,
      resetCreateSeller,
      createBankAccountToken,
      updateReceivingPolicy,
      resetBankAccounts,
      verifyDataSeller,
      finalCreateSeller
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const {
    createSellerRequesting,
    createSellerData,
    createSellerError,
    finalCreateSellerSuccess
  } = state.seller
  const {
    associateBankAccountSuccess,
    associateBankAccountError,
    updateReceivingPolicyRequest,
    updateReceivingPolicySuccess,
    updateReceivingPolicyError
  } = state.recipientMeans
  const {
    createBankAccountRequesting,
    createBankAccountSuccess,
    createBankAccountError
  } = state.bankAccount
  const { sellers, getSellersError } = state.sellers
  const marketplaceId = state.marketplace.details.id
  const { banks } = state.banks

  return {
    marketplaceId,
    sellers,
    getSellersError,
    createSellerRequesting,
    createSellerData,
    createSellerError,
    createBankAccountRequesting,
    createBankAccountSuccess,
    createBankAccountError,
    associateBankAccountSuccess,
    associateBankAccountError,
    updateReceivingPolicyRequest,
    updateReceivingPolicySuccess,
    updateReceivingPolicyError,
    finalCreateSellerSuccess,
    banks
  }
}

class BusinessForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMFA: false,
      showOwnerValidation: false,
      showBusinessValidation: false,
      showAddressValidation: false,
      showBankingValidation: false,
      currentStep: 1,
      formBanking: false,
      documentExist: false,
      emailExist: false,
      alert: false,
      businessValues: {
        type: 'business',
        document: '',
        merchant: '',
        phoneNumber: '',
        businessEmail: '',
        statement: '',
        businessName: '',
        businessOpeningDate: '',
        revenue: 0
      },
      businessOptions: {
        template: (locals) => AddSellerTemplate(locals),
        fields: {
          document: {
            label: 'CNPJ',
            factory: DocumentFactory,
            autocomplete: false,
            config: {
              id: 'ein',
              documentType: 'ein',
              onBlur: () => this.checkExistDocument('document'),
              bypassValidation: true
            }
          },
          businessName: {
            label: 'Razão Social',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'full-name'
            }
          },
          statement: {
            label: 'Nome Fantasia',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'business-name'
            }
          },
          businessEmail: {
            label: 'E-mail',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'business-email',
              onBlur: () => this.checkExistDocument('email'),
              bypassValidation: true
            }
          },
          businessOpeningDate: {
            label: 'Data da Abertura',
            factory: DatePickerFactory,
            autocomplete: false,
            config: {
              id: 'business-date',
              maxDate: moment().subtract(1, 'day').toDate(),
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          },
          phoneNumber: {
            label: 'Telefone (com DDD)',
            factory: PhoneFactory,
            autocomplete: false,
            config: {
              id: 'business-phone-number'
            }
          },
          merchant: {
            label: 'Categoria do Negócio',
            factory: SelectMerchantCodeFactory,
            autocomplete: false,
            config: {
              id: 'mcc',
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          },
          revenue: {
            label: 'Faturamento',
            factory: MoneyFactory,
            autocomplete: false,
            config: {
              id: 'revenue',
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          }
        }
      },
      ownerValues: {
        document: '',
        email: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        phoneNumber: '',
        invite: true
      },
      ownerOptions: {
        template: (locals) => AddSellerTemplate(locals),
        fields: {
          document: {
            label: 'CPF',
            factory: DocumentFactory,
            autocomplete: false,
            config: {
              id: 'taxpayer-id',
              documentType: 'taxpayer_id'
            }
          },
          email: {
            label: 'E-mail',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'email'
            }
          },
          firstName: {
            label: 'Nome',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'first-name'
            }
          },
          lastName: {
            label: 'Sobrenome',
            factory: InputDefaultFactory,
            autocomplete: false,
            attrs: {
              maxLength: 254
            },
            config: {
              id: 'last-name'
            }
          },
          birthDate: {
            label: 'Data de Nascimento',
            factory: DatePickerFactory,
            autocomplete: false,
            config: {
              id: 'birthdate',
              maxDate: moment().subtract(18, 'years').toDate(),
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          },
          phoneNumber: {
            label: 'Telefone (com DDD)',
            factory: PhoneFactory,
            autocomplete: false,
            config: {
              id: 'phone-number'
            }
          },
          invite: {
            label: 'Convidar para o Minha Conta',
            factory: CheckboxFactory,
            autocomplete: false,
            config: {
              id: 'invite'
            }
          }
        }
      },
      addressValues: {
        postalCode: '',
        line1: '',
        line2: '',
        line3: '',
        neighborhood: '',
        city: '',
        state: '',
        countryCode: 'BR'
      },
      addressOptions: {
        template: (locals) => AddAddressTemplate(locals),
        fields: {
          postalCode: {
            label: 'CEP',
            factory: PostalCodeFactory,
            autocomplete: false,
            config: {
              id: 'postal-code',
              onBlur: () => this.getAddressFromPostalCode()
            }
          },
          line1: {
            label: 'Endereço',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'address'
            }
          },
          line2: {
            label: 'Número',
            factory: NumberFactory,
            autocomplete: false,
            config: {
              id: 'number'
            }
          },
          line3: {
            label: 'Complemento (Opcional)',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'add-info'
            }
          },
          neighborhood: {
            label: 'Bairro',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'neighborhood'
            }
          },
          city: {
            label: 'Cidade',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'city'
            }
          },
          state: {
            label: 'Estado',
            factory: SelectFactory,
            autocomplete: false,
            config: {
              id: 'state',
              options: StateTypes,
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          }
        }
      },
      bankingValues: {
        bankCode: '',
        accountNumber: '',
        routingNumber: '',
        holderName: '',
        type: '',
        received: true
      },
      bankingOptions: {
        template: (locals) =>
          AddBankingTemplate(locals, () => this.handleBankingForm()),
        fields: {
          type: {
            label: 'Tipo de Conta',
            factory: SelectFactory,
            autocomplete: false,
            config: {
              id: 'type',
              options: AccountTypes,
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          },
          bankCode: {
            label: 'Banco',
            factory: SelectBankCodeFactory,
            autocomplete: false,
            config: {
              id: 'bank-code',
              options: this.props.banks,
              emptyMessage: 'Você esqueceu de selecionar aqui?'
            }
          },
          routingNumber: {
            label: 'Agência',
            factory: NumberFactory,
            autocomplete: false,
            config: {
              id: 'routing-number',
              maxLength: 6
            }
          },
          routingCheckDigit: {
            label: 'Dígito (Opcional)',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'routing-check-digit',
              maxLength: 1
            }
          },
          accountNumber: {
            label: 'Conta Corrente',
            factory: NumberFactory,
            autocomplete: false,
            config: {
              id: 'account-number',
              maxLength: 20
            }
          },
          holderName: {
            label: 'Nome do Beneficiário',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'holder-name'
            }
          },
          received: {
            label:
              'Receber transferência nesta conta (A Conta para Recebimento pode ser alterada sempre que quiser)',
            factory: CheckboxFactory,
            autocomplete: false,
            config: {
              id: 'received'
            }
          }
        }
      }
    }
    this.businessStruct = t.struct({
      document: formValidators.Document,
      statement: formValidators.String,
      merchant: formValidators.String,
      phoneNumber: formValidators.Phone,
      businessEmail: formValidators.Email,
      businessName: formValidators.String,
      businessOpeningDate: formValidators.Date,
      revenue: formValidators.MinValue(1)
    })
    this.ownerStruct = t.struct({
      document: formValidators.Document,
      email: formValidators.Email,
      firstName: formValidators.Name,
      lastName: formValidators.Name,
      birthDate: formValidators.BirthDate,
      phoneNumber: formValidators.Phone,
      invite: t.Boolean
    })
    this.addressStruct = t.struct({
      postalCode: formValidators.CEP,
      line1: formValidators.String,
      line2: formValidators.String,
      line3: t.maybe(t.String),
      neighborhood: formValidators.String,
      city: formValidators.String,
      state: t.Object,
      countryCode: formValidators.String
    })
    this.bankingStruct = t.struct({
      type: t.Object,
      bankCode: t.Object,
      routingNumber: formValidators.String,
      routingCheckDigit: t.maybe(t.String),
      accountNumber: formValidators.String,
      holderName: formValidators.Name,
      received: t.Boolean
    })
  }

  onChange = (values) => {
    const { type } = values

    if (type) {
      let { businessOptions } = this.state
      businessOptions.fields = mapValues(businessOptions.fields, (field) => {
        const shouldNotClearError =
          field.config.id === 'ein' || field.config.id === 'business-email'
        return {
          ...field,
          hasError: shouldNotClearError ? field.hasError : false,
          error: shouldNotClearError ? field.error : null
        }
      })
      this.setState({
        businessValues: values,
        businessOptions,
        showBusinessValidation: false
      })
    } else {
      let { ownerOptions } = this.state
      ownerOptions.fields = mapValues(ownerOptions.fields, (field) => {
        return {
          ...field,
          hasError: false,
          error: null
        }
      })
      this.setState({
        ownerValues: values,
        ownerOptions,
        showOwnerValidation: false
      })
    }
  }

  onChangeAddress = (values) => {
    let { addressOptions } = this.state
    addressOptions.fields = mapValues(addressOptions.fields, (field) => {
      return {
        ...field,
        hasError: false,
        error: null
      }
    })
    this.setState({
      addressValues: values,
      addressOptions,
      showAddressValidation: false
    })
  }

  onChangeBanking = (values) => {
    let { bankingOptions } = this.state
    bankingOptions.fields = mapValues(bankingOptions.fields, (field) => {
      return {
        ...field,
        hasError: false,
        error: null
      }
    })
    this.setState({
      bankingValues: values,
      bankingOptions,
      showBankingValidation: false
    })
  }

  getAddressFromPostalCode = async () => {
    const { postalCode } = this.state.addressValues
    const {
      logradouro: line1,
      complemento: line3,
      bairro: neighborhood,
      localidade: city,
      uf: state
    } = await getAddressFromPostalCode(postalCode)

    this.setState({
      addressValues: {
        ...this.state.addressValues,
        line1,
        line3,
        neighborhood,
        city,
        state: {
          label: state,
          value: state
        }
      }
    })
  }

  checkExistDocument = async (type) => {
    const { businessOptions } = this.state

    if (type === 'document') {
      const document =
        this.state.businessValues.document && this.state.businessValues.document

      const documentIsValid = t
        .validate(document, formValidators.CNPJ)
        .isValid()

      if (!document || !documentIsValid) {
        this.setState((prevState) => ({
          documentExist: false,
          businessOptions: {
            ...prevState.businessOptions,
            fields: {
              ...businessOptions.fields,
              document: {
                ...businessOptions.fields.document,
                hasError: true,
                error: 'Utilize um CNPJ válido',
                config: {
                  ...businessOptions.fields.document.config,
                  valid: false
                }
              }
            }
          }
        }))
      }
      if (documentIsValid && document && document.length === LENGTH_CNPJ) {
        const exist = await this.props.verifyDataSeller({
          ein: document,
          checkDeletedUser: true
        })

        businessOptions.fields.document.config.valid = !exist

        this.setState((prevState) => ({
          documentExist: exist,
          businessOptions: {
            ...prevState.businessOptions,
            fields: {
              ...businessOptions.fields,
              document: {
                ...businessOptions.fields.document,
                hasError: exist,
                error: exist ? 'CNPJ já cadastrado' : null
              }
            }
          }
        }))
      }
    }

    if (type === 'email') {
      const email =
        this.state.businessValues.businessEmail &&
        this.state.businessValues.businessEmail
      const emailIsValid = t.validate(email, formValidators.Email).isValid()
      if (!email || !emailIsValid) {
        this.setState((prevState) => ({
          emailExist: false,
          businessOptions: {
            ...prevState.businessOptions,
            fields: {
              ...businessOptions.fields,
              businessEmail: {
                ...businessOptions.fields.businessEmail,
                hasError: true,
                error: 'Utilize um e-mail válido',
                config: {
                  ...businessOptions.fields.businessEmail.config,
                  valid: false
                }
              }
            }
          }
        }))
      }
      if (emailIsValid && email) {
        const exist = await this.props.verifyDataSeller({
          email,
          checkDeletedUser: true
        })
        businessOptions.fields.businessEmail.config.valid = !exist
        this.setState((prevState) => ({
          emailExist: exist,
          businessOptions: {
            ...prevState.businessOptions,
            fields: {
              ...businessOptions.fields,
              businessEmail: {
                ...businessOptions.fields.businessEmail,
                hasError: exist,
                error: exist ? 'E-mail já cadastrado' : null
              }
            }
          }
        }))
      }
    }
  }

  onSubmitBusinessHandler = () => {
    const { businessValues } = this.state

    const isFormValid =
      t.validate(businessValues, this.businessStruct).isValid() &&
      !this.state.documentExist &&
      !this.state.emailExist
    this.props.verifyDataSeller({
      ein: businessValues.document,
      checkDeletedUser: true
    })

    if (!isFormValid || this.state.documentExist || this.state.emailExist) {
      this.setState({ showBusinessValidation: true })
    } else {
      this.setState({ currentStep: 2 })
    }
  }

  onSubmitOwnerHandler = () => {
    const { ownerValues } = this.state
    const isFormValid = t.validate(ownerValues, this.ownerStruct).isValid()

    if (!isFormValid) {
      this.setState({ showOwnerValidation: true })
    } else {
      this.setState({ currentStep: 3 })
    }
  }

  onSubmitAddress = () => {
    const { addressValues } = this.state
    const isFormValid = t.validate(addressValues, this.addressStruct).isValid()

    if (!isFormValid) {
      this.setState({ showAddressValidation: true })
    } else {
      this.setState({ currentStep: 4 })
    }
  }

  handleBankingForm = () =>
    this.setState({ formBanking: !this.state.formBanking })

  onSubmitForm = () => {
    const {
      businessValues,
      ownerValues,
      addressValues,
      bankingValues,
      formBanking
    } = this.state
    const {
      type,
      document: ein,
      statement,
      merchant,
      phoneNumber: phone,
      businessEmail,
      businessName,
      businessOpeningDate,
      revenue
    } = businessValues
    const {
      document,
      email,
      firstName,
      lastName,
      birthDate,
      phoneNumber,
      invite
    } = ownerValues
    const {
      postalCode,
      line1,
      line2,
      line3,
      neighborhood,
      city,
      state,
      countryCode
    } = addressValues
    const {
      bankCode,
      accountNumber,
      routingNumber,
      routingCheckDigit,
      holderName,
      type: bankingType,
      received
    } = bankingValues

    const isOwnerFormValid = t.validate(ownerValues, this.ownerStruct).isValid()
    const isBusinessFormValid = t
      .validate(businessValues, this.businessStruct)
      .isValid()
    const isAddressFormValid = t
      .validate(addressValues, this.addressStruct)
      .isValid()
    const isBankingFormValid = t
      .validate(bankingValues, this.bankingStruct)
      .isValid()

    if (!isBusinessFormValid) {
      this.setState({ showBusinessValidation: true })
    }
    if (!isOwnerFormValid) {
      this.setState({ showOwnerValidation: true })
      return
    }
    if (!isAddressFormValid) {
      this.setState({ showAddressValidation: true })
    }
    if (formBanking && !isBankingFormValid) {
      this.setState({ showBankingValidation: true })
    }

    if (
      !isBusinessFormValid ||
      !isOwnerFormValid ||
      !isAddressFormValid ||
      (formBanking && !isBankingFormValid)
    )
      return

    const data = {
      type: type,
      ein,
      revenue,
      business_email: businessEmail,
      mcc: merchant && merchant.split(':')[0],
      code: merchant && merchant.split(':')[1],
      business_phone: phone,
      business_name: businessName,
      statement_descriptor: statement,
      business_opening_date: moment(businessOpeningDate).format('YYYY/MM/DD'),
      owner: {
        taxpayer_id: document,
        first_name: firstName,
        last_name: lastName,
        birthdate: moment(birthDate).format('YYYY/MM/DD'),
        phone_number: phoneNumber,
        email
      },
      user: {
        username: businessEmail,
        password: generatePassword(16),
        invite,
        request_confirm: invite
      },
      business_address: {
        line1,
        line2,
        line3,
        neighborhood,
        city,
        state: state.value,
        postal_code: postalCode,
        country_code: countryCode
      }
    }

    let banking
    if (formBanking) {
      banking = {
        holder_name: holderName,
        bank_code: bankCode.value,
        routing_number: routingNumber,
        account_number: accountNumber,
        taxpayer_id: document,
        type: bankingType.value
      }

      if (routingCheckDigit) {
        banking['routing_check_digit'] = routingCheckDigit
      }
    }

    this.props.createSeller(
      this.props.marketplaceId,
      data,
      invite,
      banking,
      received
    )
  }

  reSubmitBanking = async () => {
    const {
      createBankAccountToken,
      marketplaceId,
      createSellerData,
      updateReceivingPolicy,
      finalCreateSeller
    } = this.props
    const { bankingValues, formBanking, businessValues } = this.state
    const {
      bankCode,
      accountNumber,
      routingNumber,
      routingCheckDigit,
      holderName,
      type: bankingType,
      received
    } = bankingValues
    const { document } = businessValues

    const isBankingFormValid = t
      .validate(bankingValues, this.bankingStruct)
      .isValid()

    if (formBanking && !isBankingFormValid) {
      this.setState({ showBankingValidation: true })
      return
    }

    let banking = {
      holder_name: holderName,
      bank_code: bankCode.value,
      routing_number: routingNumber,
      account_number: accountNumber,
      ein: document,
      type: bankingType.value
    }

    if (routingCheckDigit) {
      banking['routing_check_digit'] = routingCheckDigit
    }

    if (createSellerData) {
      await createBankAccountToken(
        marketplaceId,
        createSellerData.id,
        banking,
        received
      )
      if (
        !received &&
        this.props.createBankAccountSuccess &&
        this.props.associateBankAccountSuccess
      ) {
        finalCreateSeller()
      }
      if (
        received &&
        this.props.createBankAccountSuccess &&
        this.props.associateBankAccountSuccess
      ) {
        await updateReceivingPolicy(received, createSellerData.id)
        if (this.props.updateReceivingPolicySuccess) {
          finalCreateSeller()
        }
      }
    }
  }

  reSubmitReceivingPolicy = async () => {
    const { createSellerData, updateReceivingPolicy, finalCreateSeller } =
      this.props
    const { bankingValues, formBanking } = this.state
    const { received } = bankingValues

    if (formBanking && received) {
      await updateReceivingPolicy(received, createSellerData.id)
      if (this.props.updateReceivingPolicySuccess) {
        finalCreateSeller()
      }
    }
  }

  changeCurrentStep = (step) => this.setState({ currentStep: step })

  handleAlert = (bool) => this.setState({ alert: bool })

  onCloseFinal = () => {
    const { handleClose } = this.props
    handleClose()
    this.handleAlert(false)
  }

  onCloseHandler = () => {
    const { businessValues, ownerValues, addressValues, bankingValues } =
      this.state
    const {
      document: ein,
      statement,
      merchant,
      phoneNumber: phone,
      businessEmail,
      businessName,
      businessOpeningDate,
      revenue
    } = businessValues
    const { document, email, firstName, lastName, birthDate, phoneNumber } =
      ownerValues
    const { postalCode, line1, line2, line3, neighborhood, city, state } =
      addressValues
    const {
      bankCode,
      accountNumber,
      routingNumber,
      routingCheckDigit,
      holderName,
      type: bankingType
    } = bankingValues

    const existDataBusinessValue = isEmpty(
      compact([
        ein,
        statement,
        merchant,
        phone,
        businessEmail,
        businessName,
        businessOpeningDate,
        revenue
      ])
    )
    const existDataOwnerValues = isEmpty(
      compact([document, email, firstName, lastName, birthDate, phoneNumber])
    )
    const existDataAddressValues = isEmpty(
      compact([postalCode, line1, line2, line3, neighborhood, city, state])
    )
    const existDataBankingValues = isEmpty(
      compact([
        bankCode,
        accountNumber,
        routingNumber,
        routingCheckDigit,
        holderName,
        bankingType
      ])
    )

    if (
      existDataBusinessValue &&
      existDataOwnerValues &&
      existDataAddressValues &&
      existDataBankingValues
    ) {
      return this.onCloseFinal()
    }

    this.handleAlert(true)
  }

  render() {
    let {
      businessValues,
      businessOptions,
      ownerValues,
      ownerOptions,
      showBusinessValidation,
      showOwnerValidation,
      addressValues,
      addressOptions,
      bankingValues,
      bankingOptions,
      showAddressValidation,
      showBankingValidation,
      formBanking,
      currentStep,
      alert
    } = this.state
    const {
      goBack,
      createSellerRequesting,
      createSellerData,
      createSellerError,
      createBankAccountRequesting,
      createBankAccountError,
      associateBankAccountError,
      updateReceivingPolicyRequest,
      updateReceivingPolicyError,
      finalCreateSellerSuccess,
      resetBankAccounts,
      resetCreateSeller,
      history
    } = this.props
    const isAfterFirstStep = currentStep > 1
    const isAfterSecondStep = currentStep > 2
    const isAfterThirdStep = currentStep > 3

    if (finalCreateSellerSuccess) {
      return (
        <Fragment>
          <HeaderSidebar handleClose={this.onCloseFinal} />
          <SidebarContainer>
            <Grid.Row auto>
              <SuccessCard
                sizeButton='242px'
                onClick={() => {
                  resetBankAccounts()
                  resetCreateSeller()
                  goBack()
                }}
                textButton='Adicionar outro Estabelecimento'
              >
                <AddSellerMessageSuccess
                  name={
                    createSellerData.type === 'individual'
                      ? `${createSellerData.first_name} ${createSellerData.last_name}`
                      : createSellerData.business_name
                  }
                  onClick={() => {
                    history.push(
                      `/estabelecimento/${createSellerData.id}/configuracoes/documentos`
                    )
                    resetBankAccounts()
                    resetCreateSeller()
                  }}
                />
              </SuccessCard>
            </Grid.Row>
          </SidebarContainer>
        </Fragment>
      )
    }

    if (alert) {
      return (
        <Fragment>
          <HeaderSidebar handleClose={this.onCloseFinal} />
          <SidebarContainer>
            <AlertCloseSidebar
              labelContinue='Completar Cadastro'
              onClickContinue={() => this.handleAlert(false)}
              onClickClose={() => this.onCloseFinal()}
            >
              {
                'Você ainda não completou o cadastro\n do Estabelecimento.\n Deseja realmente sair?'
              }
            </AlertCloseSidebar>
          </SidebarContainer>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {this.state.showMFA && (
          <MFASingleComponent
            isSidebar
            showComponent={(value) =>
              this.setState((prevState) => ({
                ...prevState,
                showMFA: value
              }))
            }
            onSubmit={this.onSubmitForm}
          />
        )}
        <HeaderSidebar
          goBack={goBack}
          title={
            <>
              <strong>Adicionar</strong> Pessoa Jurídica
            </>
          }
          handleClose={this.onCloseHandler}
        />
        <SidebarContainer>
          <Grid.Col cols={16}>
            <Grid.Row>
              <Grid.Col cols={1}>
                <Step success final={isAfterFirstStep} successColor />
              </Grid.Col>
              <Grid.Col cols={14}>
                <Grid.Row auto>
                  <SubTitle
                    onClick={
                      isAfterFirstStep
                        ? () => this.changeCurrentStep(1)
                        : undefined
                    }
                    data-test='add-seller-section-business'
                    small
                    line
                  >
                    Dados <strong>da Empresa</strong>
                  </SubTitle>
                </Grid.Row>
                <Collapse isOpen={currentStep === 1}>
                  <Grid.Row auto bigMarginBottom>
                    <FormZ
                      options={
                        showBusinessValidation
                          ? getFormOptionsWithValidation(
                              businessOptions,
                              businessValues,
                              this.businessStruct
                            )
                          : businessOptions
                      }
                      struct={this.businessStruct}
                      values={businessValues}
                      onChange={this.onChange}
                      refs='businessForm'
                      disabledForm={isAfterFirstStep}
                    />
                  </Grid.Row>
                  <Grid.Row bigMarginBottom>
                    <Grid.Col cols={5}>
                      <Button
                        onClick={this.onSubmitBusinessHandler}
                        data-test='add-seller-btn-data'
                      >
                        Continuar
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row />
                </Collapse>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col cols={1}>
                <Step
                  success={isAfterFirstStep}
                  final={isAfterSecondStep}
                  successColor={isAfterFirstStep}
                />
              </Grid.Col>
              <Grid.Col cols={14}>
                <Grid.Row auto>
                  <SubTitle
                    onClick={
                      isAfterSecondStep
                        ? () => this.changeCurrentStep(2)
                        : undefined
                    }
                    data-test='add-seller-section-owner'
                    small
                    line
                  >
                    Dados <strong>do Proprietário</strong>
                  </SubTitle>
                </Grid.Row>
                <Collapse isOpen={currentStep === 2}>
                  <Grid.Row auto bigMarginBottom>
                    <FormZ
                      options={
                        showOwnerValidation
                          ? getFormOptionsWithValidation(
                              ownerOptions,
                              ownerValues,
                              this.ownerStruct
                            )
                          : ownerOptions
                      }
                      struct={this.ownerStruct}
                      values={ownerValues}
                      onChange={this.onChange}
                      refs='individualForm'
                      disabledForm={isAfterSecondStep}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col cols={5}>
                      <Button
                        onClick={() => this.changeCurrentStep(1)}
                        inversed
                        data-test='add-seller-btn-data-back'
                      >
                        Voltar
                      </Button>
                    </Grid.Col>
                    <Grid.Col cols={5}>
                      <Button
                        onClick={this.onSubmitOwnerHandler}
                        data-test='add-seller-btn-data-individual'
                      >
                        Continuar
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row />
                </Collapse>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col cols={1}>
                <Step
                  success={isAfterSecondStep}
                  final={isAfterThirdStep}
                  successColor={isAfterSecondStep}
                />
              </Grid.Col>
              <Grid.Col cols={14}>
                <Grid.Row auto>
                  <SubTitle
                    onClick={
                      isAfterThirdStep
                        ? () => this.changeCurrentStep(3)
                        : undefined
                    }
                    data-test='add-seller-section-address'
                    small
                    line
                  >
                    Endereço <strong>da Empresa</strong>
                  </SubTitle>
                </Grid.Row>
                <Collapse isOpen={currentStep === 3}>
                  <Grid.Row auto bigMarginBottom>
                    <FormZ
                      options={
                        showAddressValidation
                          ? getFormOptionsWithValidation(
                              addressOptions,
                              addressValues,
                              this.addressStruct
                            )
                          : addressOptions
                      }
                      struct={this.addressStruct}
                      values={addressValues}
                      onChange={this.onChangeAddress}
                      refs='addressForm'
                      disabledForm={isAfterThirdStep}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col cols={5}>
                      <Button
                        onClick={() => this.changeCurrentStep(2)}
                        inversed
                        data-test='add-seller-btn-address-back'
                      >
                        Voltar
                      </Button>
                    </Grid.Col>
                    <Grid.Col cols={5}>
                      <Button
                        onClick={this.onSubmitAddress}
                        data-test='add-seller-btn-address'
                      >
                        Continuar
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row />
                </Collapse>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col cols={1}>
                <Step success={isAfterThirdStep} isLastStep />
              </Grid.Col>
              <Grid.Col cols={14}>
                <Grid.Row auto>
                  <SubTitle small line data-test='add-seller-section-bank'>
                    Adicionar <strong>Conta Bancária</strong>
                  </SubTitle>
                </Grid.Row>
                <Collapse isOpen={currentStep === 4}>
                  <Grid.Row auto>
                    <CreateBankingAccount
                      hasButton={!formBanking}
                      onClick={this.handleBankingForm}
                    />
                  </Grid.Row>
                </Collapse>
                <Collapse isOpen={currentStep === 4 && formBanking}>
                  <FormZ
                    options={
                      showBankingValidation
                        ? getFormOptionsWithValidation(
                            bankingOptions,
                            bankingValues,
                            this.bankingStruct
                          )
                        : bankingOptions
                    }
                    struct={this.bankingStruct}
                    values={bankingValues}
                    onChange={this.onChangeBanking}
                    refs='bankingForm'
                    disabledForm={!formBanking}
                  />
                </Collapse>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col offset={1} cols={14}>
                {currentStep === 4 &&
                  !createSellerError &&
                  !createBankAccountError &&
                  !associateBankAccountError &&
                  !updateReceivingPolicyError && (
                    <Fragment>
                      <Grid.Row auto>
                        <Button
                          type='submit'
                          isLoading={createSellerRequesting}
                          onClick={() =>
                            this.setState((prevState) => ({
                              ...prevState,
                              showMFA: true
                            }))
                          }
                          data-test='add-seller-btn-confirm'
                        >
                          Confirmar e Adicionar Estabelecimento
                        </Button>
                      </Grid.Row>
                      <Grid.Row auto />
                    </Fragment>
                  )}
                {createSellerError && (
                  <Fragment>
                    <Grid.Row auto>
                      <AlertMessage type='error' error={createSellerError} />
                    </Grid.Row>
                    <Grid.Row auto>
                      <Button
                        type='submit'
                        isLoading={createSellerRequesting}
                        onClick={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showMFA: true
                          }))
                        }
                        data-test='add-seller-btn-re-confirm'
                      >
                        Tentar Novamente
                      </Button>
                    </Grid.Row>
                    <Grid.Row auto />
                  </Fragment>
                )}
                {(createBankAccountError || associateBankAccountError) && (
                  <Fragment>
                    <Grid.Row auto>
                      <AlertMessage
                        type='error'
                        error={
                          createBankAccountError || associateBankAccountError
                        }
                      />
                    </Grid.Row>
                    <Grid.Row auto>
                      <Button
                        type='submit'
                        isLoading={createBankAccountRequesting}
                        onClick={() => finalCreateSeller()}
                        data-test='add-seller-btn-confirm-before'
                      >
                        Continuar e cadastrar depois
                      </Button>
                    </Grid.Row>
                    <Grid.Row auto>
                      <Button
                        link
                        type='submit'
                        isLoading={createBankAccountRequesting}
                        onClick={() => this.reSubmitBanking()}
                        data-test='add-seller-btn-re-confirm'
                      >
                        Tentar Novamente
                      </Button>
                    </Grid.Row>
                    <Grid.Row auto />
                    <Grid.Row auto />
                  </Fragment>
                )}
                {updateReceivingPolicyError && (
                  <Fragment>
                    <Grid.Row auto>
                      <AlertMessage
                        type='error'
                        error={updateReceivingPolicyError}
                      />
                    </Grid.Row>
                    <Grid.Row auto>
                      <Button
                        type='submit'
                        isLoading={createSellerRequesting}
                        onClick={() => finalCreateSeller()}
                        data-test='add-seller-btn-confirm-before'
                      >
                        Continuar e cadastrar depois
                      </Button>
                    </Grid.Row>
                    <Grid.Row auto>
                      <Button
                        link
                        type='submit'
                        isLoading={updateReceivingPolicyRequest}
                        onClick={() => this.reSubmitReceivingPolicy()}
                        data-test='add-seller-btn-re-confirm'
                      >
                        Tentar Novamente
                      </Button>
                    </Grid.Row>
                    <Grid.Row auto />
                    <Grid.Row auto />
                  </Fragment>
                )}
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </SidebarContainer>
      </Fragment>
    )
  }
}

BusinessForm.propTypes = {
  goBack: PropTypes.func,
  handleClose: PropTypes.func,
  verifyDataSeller: PropTypes.func,
  marketplaceId: PropTypes.string,
  createSeller: PropTypes.func,
  createSellerRequesting: PropTypes.bool,
  createSellerData: PropTypes.object,
  createSellerError: PropTypes.object,
  resetCreateSeller: PropTypes.func,
  createBankAccountToken: PropTypes.func,
  createBankAccountRequesting: PropTypes.bool,
  createBankAccountSuccess: PropTypes.bool,
  createBankAccountError: PropTypes.object,
  associateBankAccountSuccess: PropTypes.bool,
  associateBankAccountError: PropTypes.object,
  resetBankAccounts: PropTypes.func,
  updateReceivingPolicy: PropTypes.func,
  updateReceivingPolicyRequest: PropTypes.bool,
  updateReceivingPolicySuccess: PropTypes.object,
  updateReceivingPolicyError: PropTypes.object,
  finalCreateSeller: PropTypes.func,
  finalCreateSellerSuccess: PropTypes.bool,
  history: PropTypes.any,
  banks: PropTypes.array
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessForm)
)
