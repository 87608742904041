import React from 'react'
import { Form } from './form'
import { Container, AnticipationContainer } from './styles'
import PropTypes from 'prop-types'
import Label from 'src/UIComponents/Label/Label'
import { smallText, darkGray } from 'src/Styles/settings/Constants'

const CreateAnticipation = ({ setTabIndex }) => {
  return (
    <Container>
      <AnticipationContainer>
        <Form setTabIndex={setTabIndex} />
      </AnticipationContainer>
      <br />
      <Label color={darkGray} fontSize={smallText} >
          Atenção: A Zoop não se responsabiliza por valores preenchidos incorretamente ou pela negociação entre o Marketplace e Estabelecimento Comercial.
      </Label>
    </Container>
  )
}

CreateAnticipation.propTypes = {
  setTabIndex: PropTypes.func
}

export { CreateAnticipation }
