import React from 'react'
import { NavLink } from 'react-router-dom'

import Button from 'src/UIComponents/Button/ButtonDefault'
import Can from '../../Helpers/Permission/Can'
import { useSelector } from 'react-redux'

const AnticipationComponent = () => {
  const { enableAnticipation } = useSelector(({ settings }) => {
    return {
      enableAnticipation: settings.items.prepaymentByApiEnabled
    }
  })

  if (!enableAnticipation) {
    return null
  }

  return (
    <Can I='read' a='Anticipation'>
      <NavLink
        to={'/antecipacoes'}
        data-test={`menu-item-antecipacao`}
        data-tracking={`menu-item-antecipacao`}
      >
        <Button outline ghost nowrap >Antecipação de recebíveis</Button>
      </NavLink>
    </Can>
  )
}

export default AnticipationComponent
