import React from 'react'
import Grid from '../../../UIComponents/Grid/Grid'
import Label from '../../../UIComponents/Label/Label'
import { darkGray } from '../../../Styles/settings/Constants'
import { InfoIcon, LabelWithTooltip } from './styles'
import { Tooltip } from '../../../UIComponents/Tooltip'

const Template = (locals) => (
  <Grid>
    <Grid.Row >
      <Grid.Col cols={'2'}>
      </Grid.Col>
      <Grid.Col cols={'4'} >
        <Label overflow color={darkGray}>Markup Marketplace</Label>
        {locals.inputs.markup}
      </Grid.Col>
      <Grid.Col cols={'2'}>
      </Grid.Col>
      <Grid.Col cols={'4'}>
        <Label overflow color={darkGray}>
          <LabelWithTooltip>Valor líquido solicitado <InfoIcon data-tip data-for='value-tooltip'>?</InfoIcon></LabelWithTooltip>
          <Tooltip id='value-tooltip' >
            <p>Simule a antecipação a partir do valor</p>
            <p>líquido solicitado pelo estabelecimento.</p>
            <p>O valor final é calculado a partir da soma</p>
            <p>dos recebíveis disponíveis. Se não preenchido</p>
            <p>todos os recebíveis serão antecipados.</p>
          </Tooltip>
        </Label>
        {locals.inputs.targetAmount}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export { Template }
