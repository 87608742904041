import React, { useEffect, useState } from 'react'
import HeaderSidebar from '../../../UIComponents/HeaderSidebar/HeaderSidebar'
import { SummarySimulationAnticipation } from './simulation/index'
import Title from '../../../UIComponents/Title/Title'
import Sidebar from '../../../UIComponents/Sidebar/Sidebar'
import Button from '../../../UIComponents/Button/ButtonDefault'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { getAnticipationById } from '../../../Actions/anticipations'
import { getStatusLabel } from '../../../Constants/AnticipationStatus'
import { toMoney, toPercentage, parseDate } from '../../../Utils/Utils'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import DateLoader from 'src/Components/SaleDetail/DateLoader'
import ResumeLoader from 'src/Components/SaleDetail/ResumeLoader'
import Grid from 'src/UIComponents/Grid/Grid'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Label from '../../../UIComponents/Label/Label'
import { darkGray, green } from 'src/Styles/settings/Constants'
import {
  darkerGray,
  normalText,
  smallText,
  smallerText
} from '../../../Styles/settings/Constants'
import ReactSVG from 'react-svg'
import { AnticipationPopup } from '../../../UIComponents/Popups/AnticipationPopup'
import { panelStore } from '../../../Store/panelStore'
import { createAnticipationExport } from '../../../Actions/exports'
import moment from 'moment'

export const SummaryAnticipation = () => {
  const history = useHistory()

  const dispatch = useDispatch()
  const match = useRouteMatch({
    path: '*/antecipacoes/:id',
    exact: true
  })

  const [showSidebar, setShowSidebar] = useState(!!match)
  const [anticipation, setAnticipation] = useState(null)
  const [anticipationStatus, setAnticipationStatus] = useState(null)
  const [showAnticipationPopup, setShowAnticipationPopup] = useState(false)

  const {
    marketplace: {
      details: {
        name: marketplaceName,
        customer: { ein: marketplaceDocument }
      }
    }
  } = panelStore.getState()

  const STATUS_RESUME_DICT = {
    invalid:
      'Identificamos problemas na solicitação, por isso o processo não foi concluído.',
    rejected: 'Não foi possível realizar essa antecipação.',
    canceled: 'O cancelamento dessa operação foi solicitado.',
    ready: 'Sua solicitação está sendo analisada.',
    requested: 'Sua simulação está sendo processada.',
    expired: 'A data limite de aprovação foi excedida.'
  }

  useEffect(() => {
    if (match && !showSidebar) {
      setTimeout(() => {
        setShowSidebar(true)
      }, 500)
    }

    const asyncCall = async () => {
      try {
        const response = await getAnticipationById(`${match.params.id}`)

        setAnticipation(response)
        setAnticipationStatus(response.status)
      } catch (err) {
        console.log(err)
      }
    }

    if (showSidebar && !anticipation) {
      asyncCall()
    }
  }, [match])

  const handleReceiptDownload = async (receiptType) => {
    try {
      dispatch(createAnticipationExport(match.params.id, receiptType))
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    setShowSidebar(false)
    setAnticipation(null)

    setTimeout(() => {
      history.push('/antecipacoes')
    }, 500)
  }

  const headerSubtitle = () => {
    if (!anticipation) {
      return <DateLoader />
    } else if (anticipation) {
      return parseDate(
        moment(anticipation?.created_at).subtract(3, 'hours'),
        'DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]'
      )
    }
  }

  const statusResume = anticipation && STATUS_RESUME_DICT[anticipation.status]

  return (
    <>
      {match && (
        <Sidebar show={showSidebar}>
          {anticipationStatus === 'simulated' ? (
            <SummarySimulationAnticipation
              anticipation={anticipation}
              setShowSidebar={setShowSidebar}
              setAnticipation={setAnticipation}
              setShowAnticipationPopup={setShowAnticipationPopup}
            />
          ) : (
            <>
              <HeaderSidebar
                handleClose={() => handleClose()}
                title={
                  <>
                    <strong>Resumo</strong> da antecipação
                  </>
                }
                subTitle={headerSubtitle()}
              />
              <SidebarContainer>
                {!anticipation && <ResumeLoader />}
                {anticipation && (
                  <>
                    <Grid noPadding>
                      <StatusBar
                        color={getStatusLabel(anticipation.status).color}
                      />
                      <Grid.Row auto noMargin>
                        <Label
                          fontSize={normalText}
                          color={getStatusLabel(anticipation.status).color}
                          bold
                          uppercase
                        >
                          {getStatusLabel(anticipation.status).label}
                        </Label>
                      </Grid.Row>
                      <br />
                      {statusResume && (
                        <StatusResume>{statusResume}</StatusResume>
                      )}
                      {!statusResume &&
                        anticipation?.original_request?.target_amount && (
                          <>
                            <Grid.Row>
                              <Grid.Col cols={10}>
                                <Label color={darkGray}>
                                  Valor líquido solicitado
                                </Label>
                              </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Col cols={6}>
                                <Label
                                  width='100%'
                                  textAlign='left'
                                  bold
                                  color={green}
                                >
                                  {toMoney(
                                    anticipation?.original_request
                                      ?.target_amount / 100
                                  )}
                                </Label>
                              </Grid.Col>
                            </Grid.Row>
                            <br />
                          </>
                        )}
                      <br />
                      <Grid.Row>
                        <Grid.Col cols={10}>
                          <Label color={darkGray} fontSize={smallerText}>
                            Total bruto a ser antecipado
                          </Label>
                        </Grid.Col>
                        <Grid.Col cols={6}>
                          <Label
                            width='100%'
                            textAlign='right'
                            bold
                            color={green}
                          >
                            {toMoney(
                              anticipation?.prepayment_gross_amount / 100
                            )}
                          </Label>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col cols={10}>
                          <Label color={darkGray} fontSize={smallerText}>
                            {' '}
                            Valor das taxas aplicadas{' '}
                          </Label>
                        </Grid.Col>
                        <Grid.Col cols={6}>
                          <Label
                            width='100%'
                            textAlign='right'
                            bold
                            color={green}
                          >
                            -{' '}
                            {toMoney(
                              (anticipation?.prepayment_gross_amount -
                                anticipation.prepayment_net_amount) /
                                100
                            )}
                          </Label>
                        </Grid.Col>
                      </Grid.Row>
                      <hr />
                      <Grid.Row>
                        <Grid.Col cols={10}>
                          <Label fontSize={smallerText} color={darkGray}>
                            {' '}
                            Valor líquido a ser recebido (somatório dos
                            recebíveis)
                          </Label>
                        </Grid.Col>
                        <Grid.Col cols={6}>
                          <Label
                            width='100%'
                            textAlign='right'
                            bold
                            color={green}
                          >
                            {toMoney(anticipation?.prepayment_net_amount / 100)}
                          </Label>
                        </Grid.Col>
                      </Grid.Row>
                      {anticipation.status === 'simulated' && (
                        <Grid.Row auto>
                          <Button
                            onClick={() => setShowAnticipationPopup(true)}
                          >
                            Solicitar antecipação
                          </Button>
                        </Grid.Row>
                      )}
                      <br />
                      {!statusResume && (
                        <>
                          <Grid.Row auto noMargin>
                            <Label fontSize={smallerText} color={darkGray}>
                              ID da Antecipação:
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto>
                            <Label color={darkerGray}>
                              {' '}
                              {anticipation?.id}
                            </Label>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Col cols={8}>
                              <Grid.Row auto noMargin>
                                <Label fontSize={smallerText} color={darkGray}>
                                  {' '}
                                  Markup Marketplace
                                </Label>
                              </Grid.Row>
                              <Grid.Row auto>
                                <Label color={darkerGray}>
                                  {' '}
                                  {toPercentage(
                                    anticipation?.partner_fee * 100
                                  )}
                                </Label>
                              </Grid.Row>
                            </Grid.Col>
                            <Grid.Col cols={8}>
                              <Grid.Row auto noMargin>
                                <Label fontSize={smallerText} color={darkGray}>
                                  {' '}
                                  Taxa Zoop{' '}
                                </Label>
                              </Grid.Row>
                              <Grid.Row auto>
                                <Label color={darkerGray}>
                                  {toPercentage(anticipation?.zoop_fee * 100)}
                                </Label>
                              </Grid.Row>
                            </Grid.Col>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Col cols={8}>
                              <Grid.Row auto noMargin>
                                <Label fontSize={smallerText} color={darkGray}>
                                  {' '}
                                  Data e hora da Criação
                                </Label>
                              </Grid.Row>
                              <Grid.Row auto>
                                <Label color={darkerGray}>
                                  {parseDate(
                                    anticipation.created_at,
                                    'DD/MM/yyyy HH:mm:ss'
                                  )}
                                </Label>
                              </Grid.Row>
                            </Grid.Col>
                            <Grid.Col cols={8}>
                              <Grid.Row auto noMargin>
                                <Label fontSize={smallerText} color={darkGray}>
                                  Data da Antecipação
                                </Label>
                              </Grid.Row>
                              <Grid.Row auto>
                                <Label color={darkerGray}>
                                  {parseDate(
                                    anticipation.prepayment_date,
                                    'DD/MM/yyyy'
                                  )}
                                </Label>
                              </Grid.Row>
                            </Grid.Col>
                          </Grid.Row>
                          <br />
                        </>
                      )}

                      <Title noTransform noPadding line>
                        <strong>Dados</strong> do Marketplace
                      </Title>
                      <br />
                      <Grid.Row>
                        <Grid.Col cols={8}>
                          <Grid.Row noMargin>
                            <Label fontSize={smallerText} color={darkGray}>
                              Nome
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto>
                            <Label color={darkerGray}>{marketplaceName}</Label>
                          </Grid.Row>
                        </Grid.Col>
                        <Grid.Col cols={8}>
                          <Grid.Row auto noMargin>
                            <Label fontSize={smallerText} color={darkGray}>
                              CPF/CNPJ
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto>
                            <Label color={darkerGray}>
                              {marketplaceDocument?.toDocument()}
                            </Label>
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>
                      {anticipation.status === 'succeeded' && (
                        <Grid.Row auto>
                          <IconButton
                            component
                            outline
                            ghost
                            onClick={() => handleReceiptDownload('marketplace')}
                          >
                            <Icon src='/assets/icons/banking/receipt.svg' />
                            <Label fontSize={normalText}>
                              <strong>Baixar</strong> recibo do marketplace
                            </Label>
                          </IconButton>
                        </Grid.Row>
                      )}
                      <Title noTransform noPadding line>
                        <strong>Dados</strong> do Estabelecimento
                      </Title>
                      <br />
                      <Grid.Row>
                        <Grid.Col cols={8}>
                          <Grid.Row noMargin>
                            <Label fontSize={smallerText} color={darkGray}>
                              Nome
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto>
                            <Label color={darkerGray}>
                              {anticipation.customer_info?.business_name
                                ? anticipation.customer_info.business_name
                                : `${anticipation.customer_info?.firstName} ${anticipation.customer_info?.lastName || ''}`}
                            </Label>
                          </Grid.Row>
                        </Grid.Col>
                        <Grid.Col cols={8}>
                          <Grid.Row auto noMargin>
                            <Label fontSize={smallerText} color={darkGray}>
                              CPF/CNPJ
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto>
                            <Label color={darkerGray}>
                              {anticipation.customer_info?.ein
                                ? anticipation.customer_info?.ein?.toDocument()
                                : anticipation.customer_info?.taxpayerId?.toDocument()}
                            </Label>
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>
                      {anticipation.status === 'succeeded' && (
                        <Grid.Row auto>
                          <IconButton
                            component
                            outline
                            ghost
                            onClick={() => handleReceiptDownload('seller')}
                          >
                            <Icon src='/assets/icons/banking/receipt.svg' />
                            <Label fontSize={normalText}>
                              <strong>Baixar</strong> recibo do estabelecimento
                            </Label>
                          </IconButton>
                        </Grid.Row>
                      )}
                    </Grid>
                    <br />
                  </>
                )}
              </SidebarContainer>
            </>
          )}
        </Sidebar>
      )}
      {showAnticipationPopup && (
        <AnticipationPopup
          prepaymentId={anticipation.id}
          setAnticipationPopup={setShowAnticipationPopup}
          anticipationValue={anticipation.prepayment_gross_amount}
          prepaymentDate={anticipation.prepayment_date}
        />
      )}
    </>
  )
}

const StatusBar = styled.div`
  width: 6px;
  height: 24px;
  background-color: ${(props) => props.color};
  position: absolute;
  left: 0px;
`

const StatusResume = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  padding: 1.5rem 3rem;
  border-radius: 5px;
  font-size: ${smallText};
  text-align: center;
  color: ${darkGray};
`

const Icon = styled(ReactSVG)`
  margin-right: 5px;
  svg {
    height: 1.8rem;
    width: 1.8rem;
    fill: ${({ theme: { color } }) => color};
  }
`

const IconButton = styled(Button)`
  display: flex;
  width: fit-content;
  align-items: center;
`
